<template lang="pug">
.brand-select-cards(:class="{ 'brand-select-cards-multiple': multiple, row: vertical, ...classes }")
  select-card(
    v-for="(option, index) in options"
    :vertical="vertical"
    :key="option.value"
    :icon="option.icon"
    :iconSize="option.iconSize"
    :index="index"
    :title="option.key"
    :arrow="option.arrow"
    :details="option.details"
    :value="option.value"
    :checked="isChecked(option)"
    :multiple="multiple"
    :small="option.small"
    :backgroundOff="option.backgroundOff"
    :chip="option.chip"
    @click="onClick(option)"
  )
    template(slot="backgroundContent")
      slot(name="backgroundContent")
    template(slot="rightContent")
      slot(name="rightContent")
</template>

<script>
  import SelectCard from './SelectCard.vue';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    name: 'OmSelectCards',
    components: { SelectCard },
    mixins: [designSystemMixin],
    props: {
      value: {
        type: [String, Array],
        default: null,
      },
      options: {
        type: Array,
        default: () => [],
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      vertical: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      onClick(option) {
        if (this.multiple) {
          this.$emit(
            'input',
            this.value.includes(option.value)
              ? this.value.filter((v) => v !== option.value)
              : [...(this.value || []), option.value],
          );
        } else {
          this.$emit('input', option.value);
        }
      },
      isChecked(option) {
        if (!this.value) {
          return false;
        }
        if (this.multiple) {
          return (this.value || []).includes(option.value);
        }
        return option.value === this.value;
      },
    },
  };
</script>

<style lang="sass">
  .brand-select-cards
    &-multiple
      .brand-select-card
        &-right
          display: none
        &-check
          display: block
</style>
