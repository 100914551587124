<template lang="pug">
.om-onboarding-agency-contact-details.container
  .row
    .d-none.d-lg-flex.col-lg-1(v-if="!isModal")
    .col-3(:style="{ minWidth: isModal ? '240px' : undefined }")
      om-monks.mb-4.mb-md-0(monk="business-monk")
    .om-onboarding-agency-contact-details-container.col-9.col-lg-7
      .om-onboarding-agency-contact-details-title {{ title }}
      .om-onboarding-agency-contact-details-subtitle {{ subTitle }}
      .form-group
        .row
          .col
            om-input#firstName(
              block
              v-model="firstName"
              :label="$t('onboarding.agencyDetails.firstName')"
              :placeholder="$t('firstName')"
            )
              template(slot="error" v-if="$v.firstName.$error && !$v.firstName.required")
                span {{ $t('requiredField') }}
          .col
            om-input#lastName(
              block
              v-model="lastName"
              :label="$t('onboarding.agencyDetails.lastName')"
              :placeholder="$t('lastName')"
            )
              template(slot="error" v-if="$v.lastName.$error && !$v.lastName.required")
                span {{ $t('requiredField') }}
      .form-group
        om-input#email(
          block
          v-model="email"
          type="email"
          :placeholder="$t('onboarding.agencyDetails.emailPlaceholder')"
          :label="$t('onboarding.agencyDetails.email')"
        )
          template(slot="error" v-if="$v.email.$error && !$v.email.required")
            span {{ $t('requiredField') }}
          template(slot="error" v-if="$v.email.$error && !$v.email.isCoolEmail")
            span {{ $t('invalidEmail') }}
      .form-group
        om-input#agencyName(
          block
          v-model="agencyName"
          :placeholder="$t('onboarding.agencyDetails.agencyNamePlaceholder')"
          :label="$t('onboarding.agencyDetails.agencyName')"
        )
          template(slot="error" v-if="$v.agencyName.$error && !$v.agencyName.required")
            span {{ $t('requiredField') }}
      .form-group.mt-4.d-flex.justify-content-end(v-if="isModal")
        om-button(ghost @click="$emit('close')") {{ $t('cancel') }}
        om-button.ml-2(primary @click="save") {{ $t('done') }}
      .form-group.mt-4.d-flex(v-else)
        om-button(primary :loading="nextButtonLoading" @click="next") {{ $t('next') }}
        om-button.ml-2(ghost @click="skip") {{ $t('onboarding.agencyDetails.skip') }}
</template>

<script>
  import WizardTop from '@/components/Wizard/Top.vue';
  import WizardTitle from '@/components/Wizard/Title.vue';
  import { required, email } from 'vuelidate/lib/validators';
  import SAVE_AGENCY_POTENTIAL from '@/graphql/SaveAgencyPotential.gql';
  import UPDATE_AGENCY_CONTACT_NEEDED from '@/graphql/UpdateAgencyContactNeeded.gql';
  import { mapMutations } from 'vuex';
  import navigationMixin from '../accountSetup-navigation';

  export default {
    components: { WizardTop, WizardTitle },
    mixins: [navigationMixin],
    props: {
      isModal: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      subTitle: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        nextButtonLoading: false,
        agencyName: '',
        firstName: '',
        lastName: '',
        email: '',
      };
    },
    validations: {
      agencyName: {
        required,
      },
      email: {
        required,
        isCoolEmail(v) {
          return email(v.toLowerCase());
        },
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
    },
    methods: {
      ...mapMutations(['setAgencyContactNeeded']),
      async saveAgencyPotential() {
        return this.$apollo
          .mutate({
            mutation: SAVE_AGENCY_POTENTIAL,
            variables: {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              agencyName: this.agencyName,
            },
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: this.$t('notifications.saveError'),
            });
          });
      },
      async save() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        this.$v.$reset();
        this.nextButtonLoading = true;

        try {
          await this.saveAgencyPotential();
          await this.setAgencyContactMissing(false);
        } catch (e) {
          this.nextButtonLoading = false;
          this.$notify({
            type: 'error',
            title: this.$t('notifications.saveError'),
          });
        }
        this.nextButtonLoading = false;
        this.$emit('close');
      },
      async next() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        this.$v.$reset();
        this.nextButtonLoading = true;

        try {
          await this.saveAgencyPotential();
          await this.setAgencyContactMissing(false);
          await this.finish();
          this.$router.push({ name: 'dashboard' });
        } catch (e) {
          this.nextButtonLoading = false;
          this.$notify({
            type: 'error',
            title: this.$t('notifications.saveError'),
          });
        }

        this.nextButtonLoading = false;
      },
      async skip() {
        this.setAgencyContactMissing(true);
        await this.finish();
        this.$router.push({ name: 'dashboard' });
      },
      setAgencyContactMissing(value) {
        this.setAgencyContactNeeded(value);
        return this.$apollo.mutate({
          mutation: UPDATE_AGENCY_CONTACT_NEEDED,
          variables: {
            contactNeeded: value,
          },
        });
      },
    },
  };
</script>
<style lang="sass">
  .om-onboarding-agency-contact-details
    label
      font-weight: 700
    &-container
      max-width: 472px !important
      min-width: 472px !important
    &-title
      font-weight: 700
      font-size: 24px
      margin-bottom: 1rem
    &-subtitle
      margin-bottom: 1rem
      font-size: 16px
    .form-group
      margin-bottom: .5rem
</style>
