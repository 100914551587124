const hideDialog = () => {
  const events = [
    'CookiebotOnDialogInit',
    'CookiebotOnDialogDisplay',
    'CookiebotOnConsentReady',
    'CookiebotOnLoad',
  ];

  events.forEach((event) => {
    window.addEventListener(event, function () {
      window.Cookiebot.hide();
    });
  });
};

export { hideDialog };
