import { mapActions, mapGetters } from 'vuex';
import { getAccountSetupFlow } from '@/views/Onboarding/accountSetup-flow';
import { getAccountIdFromCookie } from '@/util';
import { createCampaignAndRedirectToEditor, copyCampaignAndRedirectToEditor } from '@/router/utils';

export default {
  mixins: [],
  data() {
    return {
      step: '',
    };
  },

  computed: {
    ...mapGetters(['isNewTemplateChooserEnabled']),

    getAccountIdFromCookie() {
      return getAccountIdFromCookie();
    },
  },

  async created() {
    const { fullPath, name } = this.$route;
    this.step = name;
    await this.saveOnboardingStage(fullPath);
  },

  methods: {
    ...mapActions(['saveOnboardingStage', 'finishOnboarding']),
    async navigateToNext({ direction = null, params = null, query = null }) {
      const flow = getAccountSetupFlow()[this.step];
      if (!flow) {
        return null;
      }
      const name = direction && flow[direction] ? flow[direction] : flow;

      await this.$router.push({
        name,
        ...(query ? { query } : {}),
        params: { userId: this.getAccountIdFromCookie, ...params },
      });
    },

    async finish() {
      await createCampaignAndRedirectToEditor();
      await copyCampaignAndRedirectToEditor();
      await this.finishOnboarding();
    },
  },
};
