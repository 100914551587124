<template lang="pug">
portal(to="root")
  om-modal.agency-contact-details-modal(
    :name="name"
    :width="764"
    color="light"
    :clickToClose="true"
  )
    template(slot="modal-header")
      .brand-modal-action-icon
        close-icon.cursor-pointer(@click.native="close" :width="14" :height="14" color="#C2C2C2")
    template(slot="modal-body")
      agency-contact-form(
        :isModal="true"
        :title="$t('onboarding.agencyDetails.modalTitle')"
        :subTitle="$t('onboarding.agencyDetails.modalSubTitle')"
        @close="close"
      )
</template>
<script>
  import AgencyContactForm from '@/views/Onboarding/components/AgencyContactForm.vue';

  export default {
    components: { AgencyContactForm },
    data: () => ({ name: 'agency-contact-details' }),
    methods: {
      close() {
        this.$modal.hide(this.name);
      },
    },
  };
</script>

<style lang="sass">
  .agency-contact-details-modal
    .brand-modal-body
      padding-left: 1.5rem !important
      padding-right: 1.5rem !important
    .brand-modal-header
      padding: .5rem 0 !important
    .brand-modal-footer
      padding: .5rem 0 !important
</style>
